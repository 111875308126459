.pay-aggregator-bg,
.pay-easier-bg,
.pay-card-bg,
.sheets-bg,
.integration-bg {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

/* .pay-aggregator-bg {
  background: url("../images/Products-images/payment-platform-banner.png");
  width: 100%;
  height: 500px;
} */

.pay-easier-bg {
  background: url("../images/payout-images/pay-easier-bg.png");
}

.pay-card-bg {
  background: url("../images/payout-images/bulk-bg.png");
}

.sheets-bg {
  background: url("../images/payout-images/sheets-img-bg.png");
  width: 100%;
  height: 390px;
}

/* .payout-bg {
  background: linear-gradient(90deg, #ffffff 50%, #20313b00 60%),
    url("../images/payout-images/payout-banner.png");
  background: url("../images/payout-images/payout-banner.jpg");
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
} */

.integration-bg {
  background: url("../images/payout-images/integration.png");
  width: 100%;
  height: 450px;
  background-position: center 30% !important;
}

.integration_1-bg {
  background: url("../images/payout-images/integration1.png");
  background-size: contain;
  background-position: 40% 70%;
  background-repeat: no-repeat;
  width: 100%;
  height: 550px;
}

.pa_integration {
  background: url("../images/Products-images/pa_integration-bg.png");
  background-size: cover;
  background-position: 40%;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
}

.onox-bg {
  background: linear-gradient(90deg, #e6f7ff 50%, transparent 60%),
    url("../images/Products-images/onox-banner-bg.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

/* .whitelabel-bg {
  background: url("../images/Products-images/whitelabel/whitelabel_banner.jpg");
  background: linear-gradient(90deg, transparent 20%, white),
    url("../images/Products-images/whitelabel/banner-image.jpg");
  url("../images/Products-images/whitelabel_banner.png");
  background-size: 100% 100%;
  background-position: left;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
} */

.wl-bg {
  background: linear-gradient(90deg, #e2898533, #faebeb33);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.wl-solution-bg {
  background: url("../images/Products-images/whitelabel-support-image.png"),
    #faebeb;
  background-size: 45%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.onoxBg {
  background: url("../images/Products-images/onox_partner.png");
  background-size: contain;
  background-position: 0 100px;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.one-app-bg {
  background: url("../images/Products-images/one_app-bg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.pa_gb_bg {
  background: url("../images/Products-images/pa_grow-business-bg.png"),
    #faebeb66;
  background-size: 50% 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

/* .ecommerce-bg {
  background: linear-gradient(90deg, transparent 30%, #faebeb),
    url("../images/Industries-images/ecommerce.png");
  background: url("../images/Industries-images/ecommerce.jpg");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
} */

/* .education-bg {
  background: linear-gradient(90deg, transparent 30%, #faebeb),
    url("../images/Industries-images/education.png");
  background: url("../images/Industries-images/education.jpg");
  background-size: 100% 100%;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
} */

/* .society-bg {
  background: linear-gradient(90deg, transparent 40%, #faebeb 50%),
    url("../images/Industries-images/society.png");
  background: url("../images/Industries-images/society.jpg");
  background-size: 100% 100%;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
} */

/* .retail-bg {
  background: linear-gradient(90deg, transparent 30%, #faebeb),
    url("../images/Industries-images/retail.png");
  background: url("../images/Industries-images/retail.jpg");
  background-size: 100% 100%;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
} */

.prt-pgr-footer-bg {
  background: url("../images//partner-images/join-partner-program-footer.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.step-square {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.step-square::before {
  content: "";
  position: absolute;
  background-color: #212121;
  z-index: -1;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  transform: rotateZ(45deg);
}

.dashed_path {
  position: relative;
}

.dashed_path::before,
.dashed_path::after {
  content: "";
  position: absolute;
  z-index: -2;
  border-radius: 50%;
  width: 800%;
  height: 100px;
}

.dashed_path::before {
  bottom: -45%;
  right: 30%;
  border-bottom: 2px dashed #ec2028;
}

.dashed_path::after {
  top: -45%;
  left: 30%;
  border-top: 2px dashed #ec2028;
}

.offer-li::before {
  content: "\25CF";
  color: #32325d;
  font-weight: bold;
  display: inline-block;
  margin-left: 0;
  position: absolute;
  font-family: "FontAwesome";
  top: 0px;
  left: 0px;
}

/* .apix-bg {
  background: url("../images/Products-images/apix/apix-banner.jpg");
  background: linear-gradient(90deg, #faebeb66 50%, transparent 55%),
    url("../images/Products-images/apix/apix-banner.jpg");
  padding: 50px 0;
  background-size: 100% 100%;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
} */

.begin-api-journey {
  background: url("../images/Products-images/begin-api-now.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
}

.industries-bg {
  background: linear-gradient(90deg, #faebeb, #e2898533);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* .nuex-banner {
  background-image: url("../images/Products-images/nuex-banner.jpg");
  background-image: url("../images/Products-images/nuex-banner.jpg"),
      linear-gradient(180deg, transparent 50%, white 50%);
  background-size: 100% 100%;
  width: 100%;
} */

@media (max-width: 640px) {
  .nuex-banner {
    background-image: none;
  }

  /* .pay-aggregator-bg {
    background: url("../images/Products-images/payment-platform-banner.png"),
      linear-gradient(180deg, transparent 50%, white 50%);
    background: url("../images/Products-images/payment-platform-banner.png");
    background-size: contain !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
    height: 420px;
  } */
  .integration-bg {
    height: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .sheets-bg {
    height: auto;
  }
  .onoxBg {
    height: 300px;
  }
  .pa_gb_bg {
    background: #faebeb66;
  }
  /* .whitelabel-bg {
    height: auto;
    background-size: cover;
    background: #eff8ff;
  } */
  .blue-bg {
    min-height: 250px;
    height: auto;
    max-height: 450px;
  }

  .ecommerce-bg,
  .education-bg,
  .society-bg,
  .retail-bg,
  .onox-bg {
    background-size: contain !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  }

  /* .ecommerce-bg {
    background: linear-gradient(360deg, #faebeb 55%, transparent 55%),
      url("../images/Industries-images/ecommerce.jpg");
    background: url("../images/Industries-images/ecommerce.png");
    height: 550px;
  } */

  /* .education-bg {
    background: linear-gradient(360deg, #faebeb 55%, transparent 55%),
      url("../images/Industries-images/education.jpg");
    background: url("../images/Industries-images/education.png");
    height: 550px;
  } */

  /* .society-bg {
    background: linear-gradient(360deg, #faebeb 55%, transparent 55%),
      url("../images/Industries-images/society.jpg");
    background: url("../images/Industries-images/society.png");
    height: 520px;
  } */

  /* .retail-bg {
    background: linear-gradient(360deg, #faebeb 55%, transparent 55%),
      url("../images/Industries-images/retail.jpg");
    background: url("../images/Industries-images/retail.png");
    height: 520px;
  } */

  .onox-bg {
    background: url("../images/Products-images/onox-banner-bg.png"), #0c99d5;
    height: 500px;
  }

  /* .apix-bg {
    background: linear-gradient(180deg, transparent 55%, #faebeb66 60%),
      url("../images/Products-images/apix/apix-banner.jpg");
    background: url("../images/Products-images/apix/apix-banner.jpg");
    height: 520px;
    background-size: contain !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  } */

  /* .whitelabel-bg {
    background: linear-gradient(180deg, transparent 50%, white 50%),
      url("../images/Products-images/whitelabel_banner.png");
        url("../images/Products-images/whitelabel/whitelabel_banner.jpg");
    height: 440px;
    background-size: contain;
    background-position: top !important;
    background-repeat: no-repeat;
  } */

  /* .payout-bg {
    background: url("../images/payout-images/payout-banner.jpg"),
      linear-gradient(180deg, transparent 50%, white 50%);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    height: 400px;
  } */

  .begin-api-journey {
    background-size: cover;
  }

  .prt-pgr-footer-bg {
    background-size: cover;
  }
}

@media only screen and (max-width: 1024px) {
  .wl-solution-bg {
    background: #faebeb33;
  }
  .dashed_path::before,
  .dashed_path::after {
    display: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    h4 {
      @apply text-base;
    }
    h5 {
      @apply text-sm;
    }
    h6 {
      @apply text-xs;
    }
  }